import React, {useState, useEffect } from 'react';

import axios from 'axios'
import { Dropdown } from 'primereact/dropdown';

import Token from './auth'

export default function EventDropDown(props) {
  
    const { selectedCompany,selectedEvent, setSelectedEvent } = props;
    const [events, setEvents] = useState(null);

useEffect(() => {
    const fetchData = (event) => {  
      axios({
        method: 'get',
        url: `${process.env.REACT_APP_REPORT_SERVICE}/event?companyId=${selectedCompany.id}`,
        headers: {
          Authorization: `bearer ${Token}`,
        },
      }).then((res) => {
        setEvents(res.data.data);
      });
    };

  if(selectedCompany !== null) fetchData()
  }, [selectedCompany]);


    return (
        <div className="card flex justify-content-center">
            <Dropdown value={selectedEvent} onChange={(e) => setSelectedEvent(e.value)} options={events} optionLabel="title" 
                placeholder="Select an Event" filter className="w-full md:w-14rem" />
        </div>
    )
}