import React, {useState, useStyles, useEffect }  from "react";
import _ from "lodash";

import './bubbles.css'

const colorLegend = [
    "#2a7050",
    "#3B7E5F",
    "#48896C",
    "#519074",
    "#5C997E",
    "#5C997E",
    "#69A389",
    "#76AD94",
    "#84B8A1",
    "#92C4AE",
    "#A2D1BC",
  ];
  

const BubbleGraph = (props) => {
    const [sortedData, setSortedData] = useState([]);
    const { comfortData } = props;
  
    useEffect(() => {
      const sortedArray = _.sortBy(comfortData, ["value", "tag"]);
      setSortedData(sortedArray);
    }, [comfortData]);
  
    return (

        <div>
        {/* <h6 className={classes.mobileText}>Less Comfortable</h6> */}
        <div className={'scoreContainer'}>
            {sortedData.map((option, i) => (
            <span
                key={option.tag}
                className={'color'}
                style={{
                backgroundColor: colorLegend[i],
                }}
            >
                {option.tag}
            </span>
            ))}
        </div>
        {/* <h6 className={classes.mobileText}>More Comfortable</h6> */}
        <div className={'textBox'}>
            <h6 style={{ width: "150px"}}>
            Less Comfortable
            </h6>
            <h6 style={{ width: "150px", display: 'flex', justifyContent: 'end'}}>More Comfortable</h6>
        </div>
        </div>

    );
  };
  

  
  export default BubbleGraph;

