import React, {useState } from 'react';
import { Button } from 'primereact/button';
import CompanyDropDown from './companyDropDown'
import EventDropDown from './eventDropDown'
import SessionDropDown from './sessionDropDown'
import Table from './table' 

export default function Home() {
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [selectedSession, setSelectedSession] = useState(null);
    const [runReport, setRunReport] = useState(false);

    return (
        <>
        <div className="card flex justify-content-center">
            <CompanyDropDown  selectedCompany={selectedCompany} setSelectedCompany={setSelectedCompany}/>
            <EventDropDown selectedCompany={selectedCompany} selectedEvent={selectedEvent} setSelectedEvent={setSelectedEvent} />
            <SessionDropDown selectedEvent={selectedEvent} selectedSession={selectedSession} setSelectedSession={setSelectedSession} />
            <Button label="Submit" onClick={() => setRunReport(true)}/>
        </div>
        <div>
            <Table runReport={runReport} setRunReport={setRunReport}  selectedSession={selectedSession}/>
        </div>
        </>
    )
}