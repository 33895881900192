
import React from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import {exportExcel , exportPdf} from '../../helpers/exportFile'


export default function RemovableSortDemo(props) {

    const {wordPopcornData} = props;
    const answers = wordPopcornData.answers
    const question = wordPopcornData.question

    const cols = [
        { field: 'answer', header: 'Answer' },
        { field: 'user', header: 'Audience UserId' },

    ];

    const header = (
        <div className="flex align-items-center justify-content-space-between gap-2" style={{justifyContent: 'space-between'}}>
            <h3> {question}</h3>
            <div>
            <Button type="button" icon="pi pi-file-excel" severity="success" rounded onClick={() => exportExcel(answers)} data-pr-tooltip="XLS" />
            <Button type="button" icon="pi pi-file-pdf" severity="warning" rounded onClick={() => exportPdf(answers, cols)} data-pr-tooltip="PDF" />

            </div>
            
        </div>
    );

    return (
        <div className="card">
            <DataTable value={answers} removableSort header={header} scrollable scrollHeight="400px" tableStyle={{ minWidth: '50rem' }}>
                <Column field="answer" header="Answer" sortable style={{ width: '25%' }}></Column>
                <Column field="user" header="Audience User" sortable style={{ width: '25%' }}></Column>
            </DataTable>
        </div>
    );
}
        