
import React from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import {exportExcel , exportPdf} from '../../helpers/exportFile'


export default function RemovableSortDemo(props) {

    const {liveChats} = props;

    const cols = [
        { field: 'question', header: 'Question' },
        { field: 'audienceUserId', header: 'Audience UserId' },

    ];

    const header = (
        <div className="flex align-items-center justify-content-end gap-2">
            <Button type="button" icon="pi pi-file-excel" severity="success" rounded onClick={() => exportExcel(liveChats)} data-pr-tooltip="XLS" />
            <Button type="button" icon="pi pi-file-pdf" severity="warning" rounded onClick={() => exportPdf(liveChats, cols)} data-pr-tooltip="PDF" />
        </div>
    );

    return (
        <div className="card">
            <DataTable value={liveChats} removableSort header={header} scrollable scrollHeight="400px" tableStyle={{ minWidth: '50rem' }}>
                <Column field="question" header="Question" sortable style={{ width: '25%' }}></Column>
                <Column field="audienceUserId" header="Audience User" sortable style={{ width: '25%' }}></Column>
            </DataTable>
        </div>
    );
}
        