import React, {useState, useEffect } from 'react';

import axios from 'axios'
import { MultiSelect } from 'primereact/multiselect';

import Token from './auth'

export default function SessionDropDown(props) {
  
    const { selectedSession, setSelectedSession, selectedEvent } = props;
    const [sessions, setSessions] = useState(null);

  useEffect(() => {
    const fetchData = (event) => {  
      axios({
        method: 'get',
        url: `${process.env.REACT_APP_REPORT_SERVICE}/session?eventId=${selectedEvent.id}`,
        headers: {
          Authorization: `bearer ${Token}`,
          filtered:true
        },
      }).then((res) => {
        console.log(res, 'inside dropdown for session')
        setSessions(res.data.data);
      });
    };

  if(selectedEvent !== null) fetchData()
  }, [selectedEvent]);



    return (
        <div className="card flex justify-content-center">
            <MultiSelect value={selectedSession} onChange={(e) => setSelectedSession(e.value)} options={sessions} optionLabel="pin" 
                filter placeholder="Select Sessions" maxSelectedLabels={3} className="w-full md:w-20rem" />
        </div>
    )
}