import React, {useState, useEffect } from 'react';

import axios from 'axios'
import { Dropdown } from 'primereact/dropdown';

import Token from './auth'

export default function CompanyDropDown(props) {
  const { selectedCompany, setSelectedCompany } = props;

  const [companies, setCompanies] = useState([]);

useEffect(() => {
    const fetchData = async () => {
      axios({
        method: 'get',
        url: `${process.env.REACT_APP_AUTH_SERVICE}/companies`,
        headers: {
          Authorization: `bearer ${Token}`,
        },
      }).then((res) => {
        setCompanies(res.data.data);
      });
    };
   fetchData()
  }, []);

    return (
        <div className="card flex justify-content-center">
            <Dropdown value={selectedCompany} onChange={(e) => setSelectedCompany(e.value)} options={companies} optionLabel="name" 
                placeholder="Select a Company" filter className="w-full md:w-14rem" />
        </div>
    )
}