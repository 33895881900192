
import React from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

export default function RemovableSortDemo(props) {

    const countryBodyTemplate = (rowData) => {
       const ansers = rowData.demographics.map((demo) =>{
        return (<p key={demo.question}>{demo.question}: {demo.answer}</p>)
       } )
        return (
            <div className="flex align-items-center gap-2">
               {ansers}
            </div>
        );
    };

    return (
        <div className="card">
        
            <DataTable value={props.demographicUserrData} removableSort scrollable scrollHeight="400px" tableStyle={{ minWidth: '50rem' }}>
                <Column field="user" header="User" sortable style={{ minWidth: '200px' }}></Column>
                <Column field="demogrphics" header="Demographics" body={countryBodyTemplate} style={{ minWidth: '150px' }}></Column>
            </DataTable>
        </div>
    );
}
        