
import React, {useState, useEffect } from 'react';
import axios from 'axios'
import { Card } from 'primereact/card';
import { Accordion, AccordionTab } from 'primereact/accordion';

import LiveChatTable from './tables/liveChatTable'
import OtherQuestionsTable from './tables/otherQuestionsTable'
import WordCloudTable from './tables/wordCloudAnswers'
import WordCloudFrequencyTable from './tables/wordCloudFrequency'
import DemographicUserTable from './tables/userDemographicsTable'
import WordPopcornTable from './tables/wordPopcornTable'
import CMBubbleChart from './tables/cmBubbleChart'
import Token from './auth';


export default function Table(props) {

    const { selectedSession, runReport,setRunReport } = props;
    const [sessions, setSessions] = useState(null);
    const [title, setTitle] = useState('')
    const [participentCount, setParticipentCount] = useState(0)
    const [modulesCompleted, setModulesCompleted] = useState([])
    const [dataPoints, setDataPoints] = useState(0)
    const [npsData, setNpsData] = useState([])
    const [demographicData, setDemographicData] = useState([])
    const [demographicUserrData, setDemographicUserData] = useState([])
    const [pollData, setPollData] = useState([])
    const [liveChatData, setLiveChatData] = useState([])
    const [otherQuestions, setOtherQuestions] = useState([])
    const [wCAnswers, setWCAnswers] = useState([])
    const [wCCount, setWCCount] = useState([])
    const [wCUserCount, setWCUserCount] = useState([])
    const [wcWordFrequency, setWcWordFrequency] = useState([])
    const [wordPopcornData, setWordPopcornData] = useState([])
    const [comfortMeterData, setComfortMeterData] = useState([])
    const [cmDataByTag, setCmDataByTag] = useState([])
    const [comfortMeterScore, setComfortMeterScore] = useState([])

    // const demographicDataTypes = ['genderIdentity', 'raceIdentity', 'senorityIdentity', 
    //                                'sexualOrientationIdentity','disabilityIdentity','ageIdentity']

  useEffect(() => {
    const fetchData = (event) => {  
      axios({
        method: 'post',
        url: `${process.env.REACT_APP_REPORT_SERVICE}/get-answers-from-sessions`,
        headers: {
          Authorization: `bearer ${Token}`,
        },
        data: selectedSession

      }).then((res) => {
        console.log('res',res)
        setTitle(res.data.event_title)
        setParticipentCount(res.data.participent_count)
        setModulesCompleted(res.data.modules)
        setSessions(res.data.data)
        setNpsData(res.data.nps_data)
        setDataPoints(res.data.data_points)
        setDemographicData(res.data.demographic_data)
        setDemographicUserData(res.data.demographic_users)
        setPollData(res.data.poll_answers)
        setLiveChatData(res.data.live_chat_data)
        setOtherQuestions(res.data.otherQuestions)
        setWCAnswers(res.data.wc_answers)
        setWCCount(res.data.wc_word_count)
        setWCUserCount(res.data.wc_word_user_count)
        setWcWordFrequency(res.data.wc_word_frequency)
        setWordPopcornData(res.data.word_popcorn_data)
        setComfortMeterData(res.data.comfort_meter_data)
        setCmDataByTag(res.data.cm_data_by_tag )
        setComfortMeterScore(res.data.comfort_meter_score)
        setRunReport(false)
      });
    };

  if(runReport) fetchData()
  }, [runReport]);

    return (
        <div className="card" style={{marginTop: '25px'}}>
            <Card title="Report">
                <h3>{title}</h3>
                <p className="m-0">Participent Count:{participentCount}</p>
                <p>Data Points:{dataPoints} </p>           
                <Accordion>
                <AccordionTab header="Modules Completed">         
                <p>Modules Completed:</p>
                <div style={{marginLeft: '25px'}}>
                {modulesCompleted.map((module) => (
                    <p key={module.moduleTitle}>{module.moduleTitle}</p>
                ))}
                </div>     
                </AccordionTab>
                <AccordionTab header="NPS Data">         
                <p>NPS Data:</p>
                <div style={{marginLeft: '25px'}}>
                    {npsData.map((question, index) => {
                        return (
                            <div key={index}>
                            <p>{question.question}</p>

                            {question.answers.map((answer, index) => {
                            return (
                                <div key={index} style={{marginLeft: '20px'}}>
                                    {Object.entries(answer).map(([key, val]) => 
                                        <p key={key}>{key}: {val}</p>)}
                
                                </div>
                            );
                            })}
                            <hr />
                        </div>
                        );
                    })}
                </div>
                </AccordionTab>
                <AccordionTab header="Demographic Data">
                <p>Demographic Data:</p>
                <div style={{marginLeft: '25px'}}>
                    {demographicData.map((question, index) => {
                        return (
                            <div key={index}>
                            <p>{question.question}</p>

                            {question.answers.map((answer, index) => {
                            return (
                                <div key={index} style={{marginLeft: '20px'}}>
                                    {Object.entries(answer).map(([key, val]) => 
                                        <p key={key}>{key}: {val}</p>)}
                                </div>
                            );
                            })}
                            <hr />
                        </div>
                        );
                    })}
                </div>
                </AccordionTab>
                <AccordionTab header="Demographics by user">
                    <DemographicUserTable demographicUserrData={demographicUserrData} />
                </AccordionTab>  
                <AccordionTab header="Live Chat">
                    <LiveChatTable liveChats={liveChatData} />
                </AccordionTab>                
                <AccordionTab header="Poll Questions">
                    <p>Poll Data:</p>
                    <div style={{marginLeft: '25px'}}>
                        {pollData.map((question, index) => {
                            return (
                                <div key={index}>
                                <p>{question.question}</p>

                                {question.answers.map((answer, index) => {
                                return (
                                    <div key={index} style={{marginLeft: '20px'}}>
                                        {Object.entries(answer).map(([key, val]) => 
                                            <p key={key}>{key}: {val}</p>)}
                    
                                    </div>
                                );
                                })}

                                <hr />
                            </div>
                            );
                        })}
                    </div>
                </AccordionTab>
                <AccordionTab header="Sentiment Questions">
                    <OtherQuestionsTable otherQuestions={otherQuestions} />
                </AccordionTab>  
                <AccordionTab header="Word Cloud Data by Tags">
                <p>Word Cloud Data:</p>
                <div style={{marginLeft: '25px'}}>
                    {wCCount.map((question, index) => {
                        return (
                            <div key={index}>
                            <p>{question.question}:{question.count}</p>

                            {question.tags.map((tag, index) => {
                            return (
                                <div key={index} style={{marginLeft: '20px'}}>
                                    {tag.tag}: {tag.count}
                            {tag.demographics.map((demographics, index) => {
                            return (
                                <div key={index} style={{marginLeft: '20px'}}>

                                    {demographics.questionType} : {demographics.answer} : {demographics.count}
                                </div>
                            );
                            })}
                                </div>
                            );
                            })}
                            <hr />
                        </div>
                        );
                    })}
                </div>
                </AccordionTab>
                <AccordionTab header="Word Cloud Data by Answers">
                    <WordCloudTable wCAnswers={wCAnswers} wCUserCount={wCUserCount}/>
                </AccordionTab>                
                <AccordionTab header="Word Cloud Data by Frequency">
                    <WordCloudFrequencyTable wcWordFrequency={wcWordFrequency} />
                </AccordionTab>                 
                <AccordionTab header="Word Popcorn Data">
                    <WordPopcornTable wordPopcornData={wordPopcornData} />
                </AccordionTab>  
                <AccordionTab header="Comfort Zone Data">
                    <p>Comfort Zone Data:</p>
                    <div style={{marginLeft: '25px'}}>
                        {comfortMeterData.map((statement, index) => {
                            return (
                                <div key={index}>
                                <p>{statement.statement}:{statement.tag}</p>
                                {statement.answers.map((answer)=>{
                                    return(   
                                        Object.entries(answer).map(([key, val]) => 
                                            <p key={key}>{key}: {val}</p>))
                                })}
                                <hr />
                            </div>
                            );
                        })}
                    </div>
                </AccordionTab>  
                <AccordionTab header="Comfort Zone Data By Tag">
                    <p>Comfort Zone Data:</p>
                    <div style={{marginLeft: '25px'}}>
                        {cmDataByTag.map((tag, index) => {
                            return (
                                <div key={index}>
                                <p>{tag.tag}</p>
                                {tag.answers.map((answer)=>{
                                    return(   
                                        Object.entries(answer).map(([key, val]) => 
                                            <p key={key}>{key}: {val}</p>))
                                })}
                                <hr />
                            </div>
                            );
                        })}
                    </div>
                </AccordionTab>  
                <AccordionTab header="Comfort Zone Scores">
                    <CMBubbleChart comfortData={comfortMeterScore} /> 
                </AccordionTab>  


                </Accordion>

            </Card>
        </div>
    )
}
      