import React from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

export default function RemovableSortDemo(props) {

    return (
        <div className="card">
            {props.otherQuestions.map((question, index)=>{
               return (
                <div key={index}>
                <h3>{question.question}</h3>
                    <DataTable value={question.answers} removableSort scrollable scrollHeight="400px" tableStyle={{ minWidth: '50rem' }}>
                        <Column field="answer" header="Answer" sortable style={{ width: '25%' }}></Column>
                        <Column field="user" header="User" sortable style={{ width: '25%' }}></Column>
                    </DataTable>
                    <hr />
                </div>
               )}
            )}
        </div>

    );
}
        
